import { AuthConfig, HttpInterceptorConfig } from '@auth0/auth0-angular'

import config from '../../auth.config.json'

const { domain, clientId, audience, apiUrl, ssrApiUrl, port } = config as {
  domain: string
  clientId: string
  audience: string
  apiUrl: string
  ssrApiUrl: string
  port: string
}

const httpInterceptor: HttpInterceptorConfig = {
  allowedList: [
    `${apiUrl}/user-info`,
    {
      uri: `${apiUrl}/*`,
      allowAnonymous: true,
    },
  ],
}

const auth: AuthConfig = {
  domain: 'proximilar.us.auth0.com',
  clientId: '99WRVzoCzsFUsa2Enjxi161hdES1DT4R',
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: 'https://stocks.proximilar.com/api/v1',
    redirectUri: 'https://stocks.proximilar.com/',
    redirect_uri: 'https://stocks.proximilar.com/',
  },
}

export const ENV = {
  production: false,
  allowNgrxDebug: false,
  ssrApiUrl2: ssrApiUrl,
  apiUrl2: apiUrl,
  ssrApiUrl: 'https://stocks.proximilar.com/api/v1',
  apiUrl: '/api/v1',
  port,
  auth,
  httpInterceptor,
}
